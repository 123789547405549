/* Square path loader */

.square-path-loader {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    position: relative;
    margin: 0 auto;
}
.square-path-loader:before, .square-path-loader:after{
    content: "";
    width: 15px;
    height: 15px;
    position: absolute;
    top: 0;
    left: calc(50% - 10px);
    background-color: rgba($success, .7);
    animation: squaremove 1s ease-in-out infinite;
  }
  
  .square-path-loader:after{
    bottom: 0;
    animation-delay: 0.5s;
  }
  
  @keyframes squaremove{
    0%, 100%{
      -webkit-transform: translate(0,0) rotate(0);
      -ms-transform: translate(0,0) rotate(0);
      -o-transform: translate(0,0) rotate(0);
      transform: translate(0,0) rotate(0);
    }
  
    25%{
      -webkit-transform: translate(40px,40px) rotate(45deg);
      -ms-transform: translate(40px,40px) rotate(45deg);
      -o-transform: translate(40px,40px) rotate(45deg);
      transform: translate(40px,40px) rotate(45deg);
    }
  
    50%{
      -webkit-transform: translate(0px,80px) rotate(0deg);
      -ms-transform: translate(0px,80px) rotate(0deg);
      -o-transform: translate(0px,80px) rotate(0deg);
      transform: translate(0px,80px) rotate(0deg);
    }
  
    75%{
      -webkit-transform: translate(-40px,40px) rotate(45deg);
      -ms-transform: translate(-40px,40px) rotate(45deg);
      -o-transform: translate(-40px,40px) rotate(45deg);
      transform: translate(-40px,40px) rotate(45deg);
    }
  }
  