/* Miscellanoeous */
body,
html {
  overflow-x: hidden;
  padding-right: 0 !important; // resets padding right added by Bootstrap modal
  background: #F4F5F7;
}

*:-moz-full-screen,
*:-webkit-full-screen,
*:fullscreen *:-ms-fullscreen {
  overflow: auto;
}

.container-scroller {
  overflow: hidden;
}

pre {
  background: color(gray-lighter);
  padding: 15px;
  font-size: 14px;
}

code {
  padding: 5px;
  color: $danger;
  font-family: $type1;
  font-weight: 300;
  font-size: $default-font-size;
  border-radius: 4px;
}
.media {
  display: flex;
}
@each $color, $value in $theme-colors-purple {
  .background-#{$color} {
    @include background-variations($value); 
  }
} 
@each $color, $value in $theme-colors-hr {
  .bg-#{$color} {
    @include background-variations-hr($value); 
  }
} 

@each $color, $value in $theme-colors-sale {
  .text-#{$color} {
    @include background-variations-sale($value); 
  }
} 