@import "../support";
@import "shared";

// This is the underlying implementation for all the other mixins in this module.
// It is the only way to access prefix support for older versions of the spec.
// Deviates from canonical Compass implementation by dropping support for
// older versions of the Flexbox spec.
//
// `$properties`: map of property-value pairs that should be prefixed
@mixin flexbox($properties) {
  @each $prop, $value in $properties {
    @if $prop == display {
      @include experimental-value(display, $value, not(-moz), -webkit,
                                  not(-o), not(-ms), not(-khtml), official);
    } @else {
      @include experimental($prop, $value, not(-moz), -webkit, not(-o),
                            not(-ms), not(-khtml), official);
    }
  }
}

// Values for $display are: flex (default), inline-flex
@mixin display-flex($display: flex) {
  @include flexbox((display: $display));
}

// Values: row | row-reverse | column | column-reverse
@mixin flex-direction($direction) {
  @include flexbox((flex-direction: $direction));
}

// Values: nowrap | wrap | wrap-reverse
@mixin flex-wrap($wrap) {
  @include flexbox((flex-wrap: $wrap));
}

// Shorthand for flex-direction and flex-wrap.
@mixin flex-flow($flow) {
  @include flexbox((flex-flow: $flow));
}

// Accepts an integer
@mixin order($order) {
  @include flexbox((order: $order));
}

// Shorthand for flex-grow, flex-shrink and optionally flex-basis.
// Space separated, in that order.
@mixin flex($flex) {
  @include flexbox((flex: $flex));
}

// Accepts a number.
@mixin flex-grow($flex-grow) {
  @include flexbox((flex-grow: $flex-grow));
}

// Accepts a number.
@mixin flex-shrink($flex-shrink) {
  @include flexbox((flex-shrink: $flex-shrink));
}

// Accepts any legal value for the width property.
@mixin flex-basis($flex-basis) {
  @include flexbox((flex-basis: $flex-basis));
}

// Legal values: flex-start | flex-end | center | space-between | space-around
@mixin justify-content($justify-content) {
  @include flexbox((justify-content: $justify-content));
}

// Legal values: flex-start | flex-end | center | baseline | stretch
@mixin align-items($align-items) {
  @include flexbox((align-items: $align-items));
}

// Legal values: auto | flex-start | flex-end | center | baseline | stretch
@mixin align-self($align-self) {
  @include flexbox((align-self: $align-self));
}

// Legal values: flex-start | flex-end | center | space-between | space-around | stretch
@mixin align-content($align-content) {
  @include flexbox((align-content: $align-content));
}
