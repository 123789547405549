// ---------------------------------------------------------------------------
@include keyframes(lightSpeedIn) {
  0% {
    @include transform(translateX(100%) skewX(-30deg));
    @include opacity(0); }
  60% {
    @include transform(translateX(-20%) skewX(30deg));
    @include opacity(1); }
  80% {
    @include transform(translateX(0%) skewX(-15deg));
    @include opacity(1); }
  100% {
    @include transform(translateX(0%) skewX(0deg));
    @include opacity(1); } }


// ---------------------------------------------------------------------------
@include keyframes(lightSpeedOut) {
  0% {
    @include transform(translateX(0%) skewX(0deg));
    @include opacity(1); }
  100% {
    @include transform(translateX(100%) skewX(-30deg));
    @include opacity(0); } }