// ---------------------------------------------------------------------------
@include keyframes(rotateIn) {
  0% {
    @include transform-origin(center center);
    @include rotate(-200deg);
    opacity: 0; }
  100% {
    @include transform-origin(center center);
    @include rotate(0);
    opacity: 1; } }


// ---------------------------------------------------------------------------
@include keyframes(rotateInDownLeft) {
  0% {
    @include transform-origin(left bottom);
    @include rotate(-90deg);
    opacity: 0; }
  100% {
    @include transform-origin(left bottom);
    @include rotate(0);
    opacity: 1; } }


// ---------------------------------------------------------------------------
@include keyframes(rotateInUpLeft) {
  0% {
    @include transform-origin(left bottom);
    @include rotate(90deg);
    opacity: 0; }
  100% {
    @include transform-origin(left bottom);
    @include rotate(0);
    opacity: 1; } }


// ---------------------------------------------------------------------------
@include keyframes(rotateInUpRight) {
  0% {
    @include transform-origin(right bottom);
    @include rotate(-90deg);
    opacity: 0; }
  100% {
    @include transform-origin(right bottom);
    @include rotate(0);
    opacity: 1; } }


// ---------------------------------------------------------------------------
@include keyframes(rotateInDownRight) {
  0% {
    @include transform-origin(right bottom);
    @include rotate(90deg);
    opacity: 0; }
  100% {
    @include transform-origin(right bottom);
    @include rotate(0);
    opacity: 1; } }
